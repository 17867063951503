import React, { useEffect } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataAutocomplete } from 'components/input/Metadata/shared';
import { LinkedInGenericMetaDataResponse } from '../types/types';

interface Props extends MetadataInputProps {
    response?: LinkedInGenericMetaDataResponse;
    config: {
        accountId: string;
        location_types: string[];
    };
    value: string;
}

/**
 * LinkedIn generic metadata input component
 *
 */
const LinkedInGeneric = ({ response, config, value, onMutation, setRequest }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    if (!response || !response.data) return;

    return <MetadataAutocomplete options={response.data} value={value} onMutation={onMutation} />;
};

export default LinkedInGeneric;
