import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import MultiLevelCheckboxListItem from './item';
import { ChecklistOption, Identifier, Value } from '.';

import './../styles/section.scss';

interface Props {
    identifier?: Identifier;
    onChange: (checked: boolean, item: ChecklistOption, setPreview?: boolean) => void;
    value: Value;
    items?: any;
    item: ChecklistOption;
    onMouseHoverItem?: (key?: string) => void;
}

const MultiLevelCheckboxSection: React.FC<Props> = ({ onChange, identifier = 'key', item, value, onMouseHoverItem }) => {
    return (
        <div className="multi-level-checkbox-section">
            <Accordion defaultExpanded className="multi-level-checkbox-section__accordion" disableGutters>
                <AccordionSummary className="multi-level-checkbox-section__accordion__summary" expandIcon={<ExpandMoreIcon />}>
                    {item.label}
                </AccordionSummary>
                <AccordionDetails className="multi-level-checkbox-section__accordion__details">
                    {item.children &&
                        item.children.map((item: ChecklistOption) => {
                            return (
                                <MultiLevelCheckboxListItem
                                    key={item[identifier]}
                                    checkedItems={value.checkedItems}
                                    identifier={identifier}
                                    item={item}
                                    value={value}
                                    onChange={onChange}
                                    onMouseHoverItem={onMouseHoverItem}
                                />
                            );
                        })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default MultiLevelCheckboxSection;
