import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { CM360MetaDataList } from '../types/CM360Types';
import { SelectInput } from '../../../../SelectInput';
interface Props extends MetadataInputProps {
    response?: CM360MetaDataList;
    config: {
        accountId: string;
    };
}

/**
 * CM360 metadata input component
 *
 */
export const CM360 = ({ response, value, config, setRequest, onMutation }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, []);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (selectedValue: string) => {
        onMutation(selectedValue);
    };

    if (!response) return;

    /**
     * Function that formats the response data and creates a new object that is suitable to pass to the CM630 input component(s).
     * Because CM360 API objects are similar this function works for all the calls (for now).
     *
     * TO DO: This can be used to select the right custom inputs based on the object/channel.
     *
     */
    const createObject = () => {
        const newObject = {};
        if (response.data.campaigns) {
            response.data.campaigns.forEach((campaign) => {
                newObject[campaign.id] = campaign.name;
            });
        } else if (response.data.placements) {
            response.data.placements.forEach((placement) => {
                newObject[placement.id] = placement.name;
            });
        } else if (response.data.landingPages) {
            response.data.landingPages.forEach((landingpage) => {
                newObject[landingpage.id] = landingpage.name;
            });
        } else if (response.data.ads) {
            response.data.ads.forEach((ad) => {
                newObject[ad.id] = ad.name;
            });
        } else if (response.data.creatives) {
            response.data.creatives.forEach((creative) => {
                newObject[creative.id] = creative.name;
            });
        } else if (response.data.regions) {
            response.data.regions.forEach((region) => {
                newObject[region.id] = region.name;
            });
        } else if (response.data.postalCodes) {
            response.data.postalCodes.forEach((postalCode) => {
                newObject[postalCode.id] = postalCode.name;
            });
        } else if (response.data.platformTypes) {
            response.data.platformTypes.forEach((platformType) => {
                newObject[platformType.id] = platformType.name;
            });
        } else if (response.data.accounts) {
            response.data.accounts.forEach((account) => {
                newObject[account.id] = account.name;
            });
        } else if (response.data.advertisers) {
            response.data.advertisers.forEach((advertiser) => {
                newObject[advertiser.id] = advertiser.name;
            });
        } else if (response.data.directorySites) {
            response.data.directorySites.forEach((directorySite) => {
                newObject[directorySite.id] = directorySite.name;
            });
        }
        return newObject;
    };

    if (response) {
        const options = useMemo(() => createObject(), [response]);
        return <SelectInput options={options} value={value} onMutation={handleChange} />;
    }
};
