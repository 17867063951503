import React, { useEffect, useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataAutocomplete } from 'components/input/Metadata/shared';
import { TikTokGenericMetaData, TikTokGenericMetaDataResponse, TikTokItem } from '../types/types';
import { PropertyMapping } from '../helpers/properties-mapping';

interface Props extends MetadataInputProps {
    response?: TikTokGenericMetaDataResponse;
    config: {
        accountId: string;
        location_types: string[];
    };
    value: string;
}

/**
 * Facebook geographic metadata input component
 *
 */
const TikTokGeneric = ({ response, config, value, onMutation, setRequest, metadataType }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    const getOptions = (response: TikTokGenericMetaDataResponse | undefined): TikTokItem[] => {
        if (!response) return [];
        return response.data.map((item: TikTokGenericMetaData) => {
            const MetadataTypeProps = PropertyMapping[metadataType];
            return { id: item[MetadataTypeProps.id], name: item[MetadataTypeProps.name] };
        });
    };

    const options = useMemo(() => getOptions(response), [response]);

    if (!options) return;

    return <MetadataAutocomplete options={options} value={value} onMutation={onMutation} />;
};

export default TikTokGeneric;
