import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Illustration from 'components/ui-components-cape/Illustration';
import '../styles/media-set-selector-thumb.scss';

const getPreviewImage = (value, templates) => {
    if (value && value.assetSetup && value.assetSetup.templateIdentifier && value.type) {
        const templateIdentifier = value.assetSetup.templateIdentifier;

        const template = templates[value.type] && templates[value.type][templateIdentifier];
        const previewImage = template && template.image;
        if (previewImage) return previewImage;
        return null;
    }
    return null;
};

const MediaSetSelectorThumb = ({ value, templates }) => {
    const [previewImage, setPreviewImage] = useState(getPreviewImage(value, templates));

    useEffect(() => {
        setPreviewImage(getPreviewImage(value, templates));
    }, [value]);

    return previewImage ? (
        <div className="media-set-selector-thumb" style={{ backgroundImage: `url(${previewImage})` }}></div>
    ) : (
        <div className={classNames('media-set-selector-thumb', 'media-set-selector-thumb--empty')}>
            <Illustration size="small" color="disabled" />
        </div>
    );
};

export default MediaSetSelectorThumb;
