import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Checkbox from 'components/ui-components-v2/Checkbox';
import GenericIcon, { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import { ChecklistOption, Value } from './index';

import '../styles/item.scss';

type Identifier = 'key' | 'id';
interface Props {
    item: ChecklistOption;
    onChange: (checked: boolean, item: ChecklistOption, setPreview?: boolean) => void;
    value: Value;
    identifier: Identifier;
    paddingLeft?: number;
    checkedItems: string[];
    type?: string;
    onMouseHoverItem?: (key?: string) => void;
}

type DefaultChannel = 'meta' | 'tiktok' | 'snapchat' | 'linkedin' | 'pinterest' | 'youtube' | 'facebook' | 'instagram';

const DEFAULT_IMAGES: DefaultChannel[] = ['meta', 'tiktok', 'snapchat', 'linkedin', 'pinterest', 'youtube', 'facebook', 'instagram'];

const MultiLevelCheckboxListItem: React.FunctionComponent<Props> = ({
    item,
    onChange,
    value,
    identifier,
    paddingLeft = 0,
    checkedItems = [],
    type,
    onMouseHoverItem
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    /**
     * This function checks if a checkbox should be checked or be on indeterminate.
     * @param type The type of check we want to do
     * @returns a boolean
     */
    const checkIfTrue = (ctype: 'checked' | 'indeterminate'): boolean => {
        if (!Array.isArray(value.checkedItems)) {
            return false;
        }

        if (ctype === 'checked' && value.checkedItems.includes(item[identifier])) return true;

        const children: string[] = getChildren(item);
        let count = 0;

        children.forEach((child) => {
            if (value.checkedItems.includes(child)) count++;
        });

        if (ctype === 'checked') {
            if (count > 0 && count === children.length) return true;
        } else if (ctype === 'indeterminate') {
            if (count > 0 && count !== children.length) return true;
        }

        return false;
    };

    /**
     * This function returns an array of the keys of all the children (that don't have children themselves) of an item.
     * @param item The checklist item
     */
    const getChildren = (item: ChecklistOption) => {
        const keys: string[] = [];

        const traverse = (item: ChecklistOption) => {
            item.children?.forEach((child: ChecklistOption) => {
                keys.push(child[identifier]);
                if (child.children) traverse(child);
            });
        };

        if (item.children) traverse(item);

        return keys;
    };

    const getDefaultImage = (icon: GenericSvgIcon): JSX.Element => {
        return <GenericIcon className="multi-level-checkbox-list-item__row__icon" icon={icon} />;
    };

    // Get the label of an item. If it has an icon, show it before the text. If the icon is one
    // of the default channels, it shows that channel icon
    const getLabel = () => {
        if (!item.icon) return item.label;

        if (item.icon) {
            let iconComponent: string | JSX.Element;
            if (DEFAULT_IMAGES.includes(item.icon as DefaultChannel) && typeof item.icon === 'string') {
                iconComponent = getDefaultImage(item.icon as GenericSvgIcon);
            } else if (typeof item.icon === 'string') {
                iconComponent = <Icon className="multi-level-checkbox-list-item__row__icon">{item.icon}</Icon>;
            } else {
                iconComponent = <span className="multi-level-checkbox-list-item__row__icon">{item.icon}</span>;
            }

            return (
                <div className="multi-level-checkbox-list-item__row__label">
                    {iconComponent}
                    {item.label}
                    {item.hasErrors && (
                        <Tooltip title={'There are errors'} placement="top">
                            <span className="multi-level-checkbox-list-item__label__error"></span>
                        </Tooltip>
                    )}
                </div>
            );
        }
    };

    const changeExpand = () => {
        setExpanded(!expanded);
    };

    const handleOnMouseHover = () => {
        if (!item.children) return onMouseHoverItem?.(item?.[identifier]);
    };

    return (
        <div className="multi-level-checkbox-list-item" onMouseEnter={() => handleOnMouseHover()}>
            <div className="multi-level-checkbox-list-item__row multi-level-checkbox-list-item__row--expandable">
                {type === 'expandable' && (
                    <div className="multi-level-checkbox-list-item__row__checkbox">
                        <div className="multi-level-checkbox-list-item__row__checkbox__element">
                            <Checkbox
                                checked={checkIfTrue('checked')}
                                disabled={item.disabled}
                                indeterminate={checkIfTrue('indeterminate')}
                                onChange={(e) => onChange(e.target.checked, item)}
                            />
                        </div>

                        <div className="multi-level-checkbox-list-item__row__label" onClick={changeExpand}>
                            {getLabel()}
                            {item.children && (
                                <Icon className="multi-level-checkbox-list-item__row__expandable">{expanded ? 'expand_less' : 'expand_more'}</Icon>
                            )}
                        </div>
                    </div>
                )}
                {type !== 'expandable' && (
                    <FormControlLabel
                        className="multi-level-checkbox-list-item__row__checkbox"
                        style={{ paddingLeft: paddingLeft }}
                        label={getLabel()}
                        control={
                            <Checkbox
                                checked={checkIfTrue('checked')}
                                disabled={item.disabled}
                                indeterminate={checkIfTrue('indeterminate')}
                                onChange={(e) => onChange(e.target.checked, item)}
                            />
                        }
                    />
                )}

                {item.canPreview && (
                    <Icon
                        className={classNames('multi-level-checkbox-list-item__row__select-preview', {
                            'multi-level-checkbox-list-item__row__select-preview--selected': value.previewItem === item[identifier]
                        })}
                        onClick={() => onChange(true, item, true)}>
                        visibility
                    </Icon>
                )}
            </div>
            {(expanded || type !== 'expandable') &&
                item.children?.map((child) => {
                    return (
                        <MultiLevelCheckboxListItem
                            key={child[identifier]}
                            identifier={identifier}
                            checkedItems={checkedItems}
                            item={item.disabled ? { ...child, disabled: true } : child}
                            onChange={onChange}
                            value={value}
                            paddingLeft={paddingLeft + 12}
                            type={type}
                            onMouseHoverItem={onMouseHoverItem}
                        />
                    );
                })}
        </div>
    );
};

export default MultiLevelCheckboxListItem;
