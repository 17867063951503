export const PropertyMapping = {
    tiktokCampaign: {
        name: 'campaign_name',
        id: 'campaign_id'
    },
    tiktokAdGroup: {
        name: 'adgroup_name',
        id: 'adgroup_id'
    },
    tiktokAd: {
        name: 'ad_name',
        id: 'ad_id'
    },
    tiktokAdAccount: {
        name: 'asset_name',
        id: 'asset_id'
    },
    tiktokAccount: {
        name: 'asset_id',
        id: 'asset_id'
    }
};
