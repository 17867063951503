import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Inputs from './inputs';
import '../styles/main.scss';

const getReferenceArray = (value) => {
    const array = [];
    value.forEach(() => array.push(uuidv4()));
    return array;
};

const Repeater = ({ value, onMutation, onBlur, inputType, inputProps, max, addLabel, fullWidth, disabledIndex, disabledIndexTooltip }) => {
    const [referenceArray, setReferenceArray] = useState(getReferenceArray(value));
    const TagName = Inputs[inputType];

    const handleItemChange = (index, itemValue) => {
        value[index] = itemValue;
        onMutation(value);
    };

    const addItem = () => {
        value.push(null);
        setReferenceArray((prevState) => [...prevState, uuidv4()]);
        onMutation(value);
    };

    const removeItem = (index) => {
        value.splice(index, 1);
        setReferenceArray((prevState) => prevState.filter((_, i) => i !== index));
        onMutation(value);
    };

    return (
        <div className={classNames('input__repeater', { 'input__repeater--full-width': fullWidth })}>
            {value.map((itemValue, index) => (
                <div key={`${inputType}-${referenceArray[index]}`} onBlur={onBlur ? onBlur : undefined} className="input__repeater__item">
                    <Tooltip
                        title={disabledIndex !== undefined && disabledIndexTooltip && disabledIndex === index ? disabledIndexTooltip : ''}
                        disableInteractive>
                        <div>
                            <TagName
                                value={itemValue}
                                {...inputProps}
                                disabled={disabledIndex === index}
                                onMutation={(itemValue) => handleItemChange(index, itemValue)}
                            />
                        </div>
                    </Tooltip>
                    <IconButton
                        style={{ opacity: index > 0 ? 1 : 0, pointerEvents: index > 0 ? 'auto' : 'none' }}
                        onClick={() => removeItem(index)}
                        size="small"
                        disabled={disabledIndex === index}
                        className="input__repeater__item__btn">
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            ))}

            {(max === 0 || value.length < max) && (
                <Button
                    onClick={addItem}
                    variant="outlined"
                    className={classNames('input__repeater__add-button', { 'input__repeater__add-button--full-width': fullWidth })}
                    startIcon={<Icon>add</Icon>}
                    color="primary">
                    {addLabel ? addLabel : Translation.get('actions.add', 'common')}
                </Button>
            )}
        </div>
    );
};

Repeater.propTypes = {
    onMutation: PropTypes.func,
    value: PropTypes.array,
    max: PropTypes.number,
    inputType: PropTypes.string,
    inputProps: PropTypes.object,
    addLabel: PropTypes.string,
    fullWidth: PropTypes.bool
};

Repeater.defaultProps = {
    onMutation: () => {},
    value: [null],
    max: 0,
    inputProps: {},
    fullWidth: false
};

export default Repeater;
