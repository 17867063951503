import React, { useEffect, useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { LinkedInMetaDataList } from '../types/LinkedInTypes';
import { SelectInput } from '../../../../../SelectInput';

interface Props extends MetadataInputProps {
    response?: LinkedInMetaDataList;
    config: {
        q?: string;
        facet?: string;
    };
}

/**
 * LinkedIn metadata input component
 *
 */
export const LinkedInAdBudget = ({ response, value, config, setRequest, onMutation }: Props) => {
    useEffect(() => {
        setRequest({
            params: { ...config }
        });
    }, []);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (selectedValue: string) => {
        onMutation(selectedValue);
    };

    if (!response) return;

    /**
     * TO DO: Unclear What to do with all the data, this component is not yet functional due it is not clear how data should be displayed to the user.
     * Function that formats the response data and creates a new object that is suitable to pass to the linkedIn input component(S).
     * This can be used to render custom inputs based on the object/channel.
     *
     */
    const createObject = () => {
        const newObject = {};
        response.data.forEach((item) => {
            newObject[item.urn] = item.name;
        });
        return newObject;
    };

    if (response) {
        const options = useMemo(() => createObject(), [response]);
        return <SelectInput options={options} value={value} onMutation={handleChange} />;
    }
};
