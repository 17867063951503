import '../styles/main.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Checkbox from 'components/ui-components-v2/Checkbox';

/**
 * Select a visual
 * This input field allows you to choose between different images, and sets the final value to the value of the selected image.
 */
export default class SelectVisual extends Component {
    static propTypes = {
        value: PropTypes.any,
        /** Can be either 'single' or 'multiple' */
        selectType: PropTypes.string,
        /** Should a checkbox be shown over the image */
        showCheckbox: PropTypes.bool,
        /** Width of each item */
        itemWidth: PropTypes.number,
        /** Height of each item */
        itemHeight: PropTypes.number,
        /** Font size of the title */
        fontSize: PropTypes.number,
        /** Options to add to the select */
        options: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
                image: PropTypes.string
            })
        ),
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
    };

    static defaultProps = {
        value: [],
        itemWidth: 70,
        itemHeight: 70,
        fontSize: 14,
        options: [],
        selectType: 'single',
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: (props.value ? props.value : props.selectType === 'multiple') ? [] : ''
        };
    }

    /**
     * Select field
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.value != this.props.value && this.props.value != this.state.value) {
            this.setState({ value: this.props.value });
        }
    };

    /**
     * Value changed, show new  value
     */
    onValueChanged = (event, checked) => {
        this.toggleSelectable(event.target.value, checked);
    };

    /**
     * Toggle selectable option
     */
    toggleSelectable = (key, checked) => {
        const { selectType } = this.props;

        if (selectType === 'multiple') {
            let newValue = [];
            if (checked) {
                newValue = [...this.state.value, key];
            } else {
                newValue = this.state.value.filter((x) => x !== key);
            }
            this.setState({ value: newValue }, this.updateItem);
        } else {
            this.setState({ value: key }, this.updateItem);
        }
    };

    /**
     * Update the active value in the datasource
     */
    updateItem = () => {
        const { onMutation } = this.props;
        onMutation(this.state.value);
    };

    /**
     * Render an individual item
     */
    renderSelectable = (item, index) => {
        const { itemWidth, itemHeight, fontSize, showCheckbox, scaling } = this.props;
        const { value } = this.state;
        const imgStyle = {
            width: `${itemWidth}px`,
            height: `${itemHeight}px`,
            backgroundImage: "url('" + item.image + "')"
        };

        if (scaling === 'cover') {
            imgStyle['backgroundSize'] = 'cover';
        }

        let isChecked = false;

        if (typeof value === 'string' && value == item.key) {
            isChecked = true;
        } else if (Array.isArray(value) && value.includes(item.key)) {
            isChecked = true;
        }

        return (
            <div className="input__select-visual__item" key={index}>
                <div
                    className={'input__select-visual__item__box ' + (isChecked && !showCheckbox ? 'input__select-visual__item__box--selected' : '')}
                    onClick={() => this.toggleSelectable(item.key, !isChecked)}>
                    <div className="input__select-visual__item__box__image" style={imgStyle} alt={item.description}></div>
                    {showCheckbox && (
                        <Checkbox
                            size="small"
                            color="default"
                            className="input__select-visual__item__box__checkbox"
                            onChange={this.onValueChanged}
                            value={item.key}
                            checked={isChecked}
                        />
                    )}
                    {isChecked && !showCheckbox && <div className="selected-overlay"></div>}
                </div>
                <div className="input__select-visual__item__title" style={{ width: itemWidth + 'px', fontSize: fontSize + 'px' }}>
                    {item.title}
                </div>
            </div>
        );
    };

    render() {
        const { options } = this.props;

        return <div className="input__select-visual">{options.map((item, index) => this.renderSelectable(item, index))}</div>;
    }
}
