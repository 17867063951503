import React, { useState } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import PresetsDialog from 'components/bricks/components/shared/components/presets-dialog/components';
import { Preset } from 'components/bricks/types/preset';
import '../styles/main.scss';
import PresetSelectorItem from './item';

interface Props {
    multiple?: boolean;
    value?: Preset[];
    onMutation: (value: Preset[]) => void;
}

const PresetSelector: React.FC<Props> = ({ multiple = false, value = [], onMutation }) => {
    const [presetsDialogOpen, setPresetsDialogOpen] = useState<boolean>(false);

    /**
     * Selects preset(s)
     * @param selectedPresets
     */
    const handleSelect = (selectedPresets: Preset[]) => {
        if (!multiple) onMutation([selectedPresets[0]]);
        else onMutation(selectedPresets);
        setPresetsDialogOpen(false);
    };

    /**
     * Removes preset
     * @param preset
     */
    const removePreset = (preset: Preset) => {
        const newValue = [...value];

        const indexOfPreset = newValue.indexOf(preset);
        if (indexOfPreset > -1) {
            newValue.splice(indexOfPreset, 1);
        }

        onMutation(newValue);
    };

    return (
        <div className="input-preset-selector">
            <span className="input-preset-selector__title">Select presets</span>
            {(!value || value.length === 0 || multiple) && (
                <Button variant="outlined" className="input-preset-selector__add-preset" onClick={() => setPresetsDialogOpen(true)}>
                    <div className="input-preset-selector__add-preset__icon-wrapper">
                        <Icon className="input-preset-selector__add-preset__icon">add</Icon>
                    </div>
                    <div className="input-preset-selector__add-preset__copy">
                        <div className="input-preset-selector__add-preset__copy__title">{`Add preset${multiple ? 's' : ''}`}</div>
                        <div className="input-preset-selector__add-preset__copy__description">Match uploads to preset criteria</div>
                    </div>
                </Button>
            )}

            {value && Array.isArray(value) && value.length > 0 && (
                <>
                    {value.map((preset: Preset, index: number) => (
                        <PresetSelectorItem
                            multiple={multiple}
                            preset={preset}
                            setPresetsDialogOpen={setPresetsDialogOpen}
                            removePreset={removePreset}
                            key={index}
                        />
                    ))}
                </>
            )}

            {presetsDialogOpen && <PresetsDialog onSelect={handleSelect} onClose={() => setPresetsDialogOpen(false)} value={value} multiple={multiple} />}
        </div>
    );
};

export default PresetSelector;
