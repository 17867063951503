import Copy from 'components/input/Copy';
import CopyMultiLanguage from 'components/input/CopyMultiLanguage';
import Link from 'components/input/Link';
import DatePicker from 'components/input/DatePicker';
import DateTimePicker from 'components/input/DateTimePicker';
import DateRangePicker from 'components/input/DateRangePicker';
import TimePicker from 'components/input/TimePicker';
import Number from 'components/input/Number';

export default {
    text: Copy,
    textMultiLanguage: CopyMultiLanguage,
    link: Link,
    date: DatePicker,
    datetime: DateTimePicker,
    time: TimePicker,
    dateRange: DateRangePicker,
    number: Number
};
