import React, { useEffect, useMemo } from 'react';
import { FilterOptionsState } from '@mui/base';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import TextField from 'components/ui-components/TextField';
import { AutocompleteMetadataOption } from '../types/types';
import '../styles/main.scss';

interface Props {
    options: AutocompleteMetadataOption[];
    value?: string;
    readOnly?: boolean;
    onMutation: (value: unknown, type?: string) => void;
}

const MetadataAutocomplete = ({ options, value, readOnly, onMutation }: Props) => {
    useEffect(() => {
        if ((!value || !value.length) && options.length) return handleChange({ id: options[0].id, name: options[0].name });
    }, []);

    /**
     * Creates a value as object so it can be passed as value to the Autocomplete
     */
    const valueAsObject: AutocompleteMetadataOption | null = useMemo(() => {
        if (!options || !options.length) return null;
        const found = options.find((item: AutocompleteMetadataOption) => item.id === value);

        return found || null;
    }, [value, options]);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (value: AutocompleteMetadataOption | null) => {
        if (!value || value === null) return onMutation(undefined);
        onMutation(value.id);
    };

    return (
        <Autocomplete
            disabled={readOnly}
            options={options}
            style={{ width: '100%' }}
            value={valueAsObject}
            onChange={(event, value) => handleChange(value)}
            renderOption={(props: object, option: AutocompleteMetadataOption) => (
                <div {...props} className="metadata-generic-autocomplete-input__option">
                    <span className="metadata-generic-autocomplete-input__option__name">{option.name}</span>
                    <span className="metadata-generic-autocomplete-input__option__id">ID: {option.id}</span>
                </div>
            )}
            filterOptions={(options: AutocompleteMetadataOption[], state: FilterOptionsState<AutocompleteMetadataOption>) => {
                return options.filter((option: AutocompleteMetadataOption) => option?.name.includes(state.inputValue) || option.id.includes(state.inputValue));
            }}
            getOptionLabel={(option: AutocompleteMetadataOption) => option.name + ` (${option.id})`}
            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
        />
    );
};

export default MetadataAutocomplete;
