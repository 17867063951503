import React, { useEffect, useMemo, useState } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataSearch } from 'components/input/Metadata/shared';
import { TikTokLocationMetaDataList, TikTokMetadataItem } from '../types/types';
import { PropertyMapping } from '../helpers/properties-mapping';

interface Props extends MetadataInputProps {
    response?: TikTokLocationMetaDataList;
    config: Config;
    value: TikTokMetadataItem[];
}

interface Config {
    accountId?: string;
    type?: string;
    searchClass?: string;
    q?: string;
    osType?: string;
    locations?: string[];
}

const TikTokGenericSelectMultiple = ({ response, config, value, setRequest, onMutation, metadataType }: Props) => {
    const [q, setQ] = useState<string | undefined>('');
    const [customConfig, setCustomConfig] = useState<Config>(config);

    useEffect(() => {
        if ((q || q !== '') && metadataType === 'tiktokInterestsAndBehaviors')
            // set parameters to start a request
            setRequest({
                params: {
                    ...config,
                    keyword: q
                }
            });
        else if (metadataType === 'tiktokCarriers' && config.locations) {
            // set parameters to start a request
            setRequest({
                params: {
                    ...config
                }
            });
        } else if (!response && metadataType !== 'tiktokInterestsAndBehaviors') {
            // set parameters to start a request
            setRequest({
                params: {
                    ...config
                }
            });
        }
    }, [JSON.stringify(config), q]);

    //Remove devices if their os type is not selected
    useEffect(() => {
        if (!config.osType || config.osType === 'ALL' || !value) return;

        const newValue = value.filter((item) => item.os_type === config.osType?.toUpperCase());

        onMutation(newValue);
    }, [config.osType]);

    // Remove carriers based on location
    useEffect(() => {
        if (!config.locations || !config.locations.length || !value) return;

        const newValue = value
            .map((item) => {
                const countryCode = item.name.substring(0, 2);
                if (config.locations?.includes(countryCode)) return item;
            })
            .filter((item) => item);
        onMutation(newValue);
    }, [config.locations]);

    // Formatting the options based on the resposne
    const getOptions = (response: TikTokLocationMetaDataList | undefined): TikTokMetadataItem[] => {
        if (!response || !response.data || !response.data.length) return [];

        // Mapped properties of responses of the metadata
        const MetadataProperties = PropertyMapping[metadataType];
        return response.data.reduce((acc: TikTokMetadataItem[], item) => {
            if (config.osType && config.osType !== 'ALL' && item.os_type && item.os_type.toLocaleUpperCase() !== config.osType) return acc;

            // Check if the item is not selected already
            const isItemSelected = value
                ? value.some((selectedItem: TikTokMetadataItem) => selectedItem[MetadataProperties.id] === item[MetadataProperties.id])
                : false;

            // Retrieve the options which include q
            if (!isItemSelected && q && item[MetadataProperties.name].toLowerCase().includes(q.toLowerCase()))
                acc.push({ ...item, id: item[MetadataProperties.id], type: metadataType, name: item[MetadataProperties.name] });
            return acc;
        }, []);
    };

    const options = useMemo(() => getOptions(response), [response, q, config.osType, config.locations]);

    return (
        <MetadataSearch
            isQueryParamRequired
            options={options}
            value={value}
            onMutation={onMutation}
            setCustomConfig={setCustomConfig}
            customConfig={customConfig}
            q={q}
            setQ={setQ}
        />
    );
};

export default TikTokGenericSelectMultiple;
