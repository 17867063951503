import React, { useEffect, useMemo } from 'react';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import TextField from 'components/ui-components/TextField';
import { SnapchatGenericMetaData, SnapchatGenericMetaDataResponse, SnapchatItem } from '../types/types';
import { ItemNameMapping } from '../helpers/item-name-mapping';

interface Props extends MetadataInputProps {
    response?: SnapchatGenericMetaDataResponse;
    config: {
        accountId: string;
        location_types: string[];
    };
    value: SnapchatGenericMetaData[];
}

/**
 * Facebook geographic metadata input component
 *
 */
const SnapchatGeneric = ({ response, config, value, onMutation, setRequest, metadataType }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    const getOptions = (response: SnapchatGenericMetaDataResponse | undefined): SnapchatItem[] => {
        if (!response) return [];
        return response.data.map((item: SnapchatGenericMetaData) => {
            const itemName = ItemNameMapping[metadataType];
            return item[itemName];
        });
    };

    const options = useMemo(() => getOptions(response), [response]);

    /**
     * Converts the response to object which fits the autocomplete
     * @param id
     * @returns object to be used from the autocomplete
     */
    const getValueAsObject = (id): SnapchatItem | undefined => {
        if (!options || !options.length) return undefined;
        const found = options.find((item: SnapchatItem) => item.id === id);
        return found;
    };

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (event, value) => {
        if (!value || value === null) return onMutation(undefined);
        onMutation(value.id);
    };

    if (!options) return;

    return (
        <Autocomplete
            options={options}
            style={{ width: '100%' }}
            onChange={handleChange}
            value={getValueAsObject(value)}
            getOptionLabel={(option: SnapchatItem) => option.name}
            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
        />
    );
};

export default SnapchatGeneric;
