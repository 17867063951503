import React, { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import '../styles/main.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import TextField from 'components/ui-components/TextField';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { FacebookGeographicMetaData, FacebookGeographicMetaDataList, DialogObject } from '../types/FacebookGeographicTypes';
import RadiusDialog from './radius-dialog';
import ValueBlock from './value-block';

interface Props extends MetadataInputProps {
    response?: FacebookGeographicMetaDataList;
    config: Config;
    value: FacebookGeographicMetaData[] | string | number | string[];
}

interface Config {
    accountId: string;
    location_types: string[];
    q?: string;
}

const MIN_RADIUS = 17;
const MAX_RADIUS = 80;

/**
 * Facebook geographic metadata input component
 *
 */
const FacebookGeographic = ({ response, config, value, setRequest, onMutation }: Props) => {
    const [dialog, setDialog] = useState<DialogObject>({ dialogOpen: false, dialogIndex: -1, dialogRadius: 0 });
    const [q, setQ] = useState<string>('');
    const [customConfig, setCustomConfig] = useState<Config>(config);

    useEffect(() => {
        if (typeof value === 'string') onMutation(value.split(','));
        else if (typeof value === 'number') onMutation([value.toString()]);
    }, []);

    useEffect(() => {
        setRequest({
            params: {
                ...customConfig,
                type: 'adgeolocation',
                location_types: config.location_types
                    ? config.location_types
                    : 'country,region,city,zip,place,geo_market,large_geo_area,medium_geo_area,small_geo_area,metro_area,subcity,neighborhood,subneighborhood'
            }
        });
    }, [JSON.stringify(customConfig)]);

    const handleChangeCustomConfig = (newQ: string) => {
        setCustomConfig({ ...customConfig, q: newQ });
    };

    // Changes the config for the request
    const handleChangeConfig = useCallback(debounce(handleChangeCustomConfig, 500), []);

    const handleSetQ = (event) => {
        setQ(event.target.value);
        handleChangeConfig(event.target.value);
    };

    /**
     * Hightlights the the result based on text in the input field
     * @param country of the location
     * @param title of the location
     * @param highlight which should be hightlighted
     * @returns
     */
    const getHighlightedText = (country: string, title: string, highlight: string): ReactJSXElement => {
        if (!highlight) highlight = '';
        let text = '';
        if (country) {
            text = country + ': ';
        }
        if (title) {
            text = text + title;
        }

        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    /**
     * Add a location
     * @param {*} item
     */
    const handleLocationClick = (item) => {
        let newValue: Array<object | string> = [];
        if (typeof value === 'string' || typeof value === 'number') return;
        if (value) newValue = value;

        if (item.type == 'city') {
            item.radius = MIN_RADIUS;
        }
        if (item.type == 'place') {
            item.radius = 0;
        }

        newValue.push(item);
        onMutation(newValue);
        setQ('');
    };

    // Close the available items
    const handleClickAway = () => {
        if (q && q.length) setQ('');
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="input__facebook-geographic">
                <div className="input_facebook-geographic__input">
                    <TextField fullWidth onChange={handleSetQ} value={q} margin="none" variant="outlined"></TextField>
                </div>
                {response && !!response.data.length && q && q !== '' && (
                    <div className="input__facebook-geographic__results">
                        {response?.data.map((item, i) => (
                            <div className="input__facebook-geographic__results__item" onClick={() => handleLocationClick(item)} key={`result-${i}`}>
                                <div className="input__facebook-geographic__results__item__name">{getHighlightedText(item.country_name, item.name, q)}</div>
                                <div className="input__facebook-geographic__results__item__type">{item.type}</div>
                            </div>
                        ))}
                    </div>
                )}

                {value && Array.isArray(value) && value.length > 0 && (
                    <ValueBlock
                        value={value}
                        getHighlightedText={getHighlightedText}
                        onMutation={onMutation}
                        setDialog={setDialog}
                        q={q}
                        minRadius={MIN_RADIUS}
                    />
                )}
                {dialog && dialog.dialogOpen && Array.isArray(value) && (
                    <RadiusDialog value={value} minRadius={MIN_RADIUS} maxRadius={MAX_RADIUS} onMutation={onMutation} dialog={dialog} setDialog={setDialog} />
                )}
            </div>
        </ClickAwayListener>
    );
};

export default FacebookGeographic;
