import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import EditIcon from '@mui/icons-material/Edit';
import Translation from 'components/data/Translation';
import { FacebookGeographicMetaData, DialogObject } from '../types/FacebookGeographicTypes';

interface Props {
    value: FacebookGeographicMetaData[] | string[];
    onMutation: (value: unknown, type?: string) => void;
    getHighlightedText: (country: string, title: string, highlight: string) => ReactJSXElement;
    setDialog: React.Dispatch<React.SetStateAction<DialogObject>>;
    q: string;
    minRadius: number;
}

const ValueBlock = ({ value, getHighlightedText, onMutation, setDialog, q, minRadius }: Props) => {
    /**
     * Handle remove location
     * @param {*} index
     */
    const handleRemove = (index) => {
        const newValue = value;
        value.splice(index, 1);
        onMutation(newValue);
    };

    /**
     *
     * @param index Opens the dialog
     */
    const dialogOpen = (index) => {
        const item = value[index];
        if (typeof item === 'string') return;
        setDialog({ dialogOpen: true, dialogIndex: index, dialogRadius: item.radius ? item.radius : minRadius });
    };

    return (
        <div className="input__facebook-geographic__list">
            <div className="input__facebook-geographic__list__title"> {Translation.get('input.metadata.facebook.geographic.selectedLocations', 'common')}</div>

            {value &&
                value.map((item, i) => (
                    <div className="input__facebook-geographic__list__item" key={'list' + i}>
                        <div className="input__facebook-geographic__list__item__name">
                            {typeof item === 'object' ? getHighlightedText(item.country_name, item.name, q) : item}
                        </div>{' '}
                        {typeof item === 'object' && <div className="input__facebook-geographic__list__item__type">({item.type})</div>}
                        {typeof item === 'object' && item.radius && item.radius > 0 && (
                            <div className="input__facebook-geographic__list__item__distance">{`+${item.radius} km`}</div>
                        )}
                        <div className="input__facebook-geographic__list__item__actions">
                            <div className="input__facebook-geographic__list__item__actions__edit" onClick={() => dialogOpen(i)}>
                                {(item.type === 'city' || item.type === 'zip') && (
                                    <IconButton size="small">
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </div>
                            <div className="input__facebook-geographic__list__item__actions__remove" onClick={() => handleRemove(i)}>
                                <IconButton size="small">
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ValueBlock;
