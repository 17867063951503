import React, { useEffect, useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { LinkedInMetaDataList } from '../types/LinkedInTypes';
import { SelectInput } from '../../../../../SelectInput';

interface Props extends MetadataInputProps {
    response?: LinkedInMetaDataList;
    config: {
        q?: string;
        facet?: string;
    };
}

/**
 * LinkedIn metadata input component
 *
 */
export const LinkedInBlockList = ({ response, value, config, setRequest, onMutation }: Props) => {
    useEffect(() => {
        setRequest({
            params: { ...config }
        });
    }, []);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (selectedValue: string) => {
        onMutation(selectedValue);
    };

    if (!response) return;

    /**
     *
     * Function that formats the response data and creates a new object that is suitable to pass to the linkedIn input component(S).
     * This can be used to render custom inputs based on the object/channel.
     *
     */
    const createObject = () => {
        const newObject = {};
        if (response) {
            response.data.forEach((item) => {
                newObject[item.entityTypes[0]] = item.facetName;
            });
        }
        return newObject;
    };

    if (response) {
        const options = useMemo(() => createObject(), [response]);
        return <SelectInput options={options} value={value} onMutation={handleChange} />;
    }
};
