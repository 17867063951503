import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import DOMPurify from 'dompurify';

const CKEditor = React.lazy(() => import('./editor-lazy'));

/**
 * RichTextEditor
 * A wrapper around CKEditor.
 */
export class RichTextEditor extends Component {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        maxLength: PropTypes.number,
        minLength: PropTypes.number,
        /* Possible options: "heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "imageUpload", "blockQuote", "insertTable", "mediaEmbed", "undo", "redo" */
        includedOptions: PropTypes.array,
        excludedOptions: PropTypes.array,
        addTargetToExternalLinks: PropTypes.bool,
        value: PropTypes.any,
        onMutation: PropTypes.func
    };

    static defaultProps = {
        className: '',
        disabled: false,
        includedOptions: ['bold', 'italic', 'underline', 'link', 'bulletedList', 'numberedList'],
        editorConfig: {},
        excludedOptions: [],
        addTargetToExternalLinks: true,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        // Setup the toolbar
        const allOptions = [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo'
        ];
        let toolbar = allOptions;
        if (props.includedOptions) {
            toolbar = props.includedOptions;
        } else if (props.excludedOptions) {
            toolbar = allOptions.filter((x) => !props.excludedOptions.includes(x));
        }

        // Set the state
        this.state = {
            editorConfig: {
                toolbar,
                addTargetToExternalLinks: props.addTargetToExternalLinks
            },
            errors: [],
            value: props.value
        };
    }

    /**
     * ComponentDidUpdate
     * This handles value changes
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value && this.props.value != this.state.value) {
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Change handler
     * Mutates on changes
     */
    onChangeHandler = (event, editor) => {
        const { onMutation } = this.props;
        const { minLength, maxLength } = this.props;
        const data = editor.getData();

        // Min and max length handle
        const strippedString = data.replace(/(<([^>]+)>)/gi, '');
        const characters = strippedString.length;
        const errors = [];

        if (characters < minLength) {
            errors.push(`Minimum characters is: ${minLength}`);
        } else if (characters > maxLength) {
            errors.push(`Maximum characters is: ${maxLength}`);
        }
        const minValid = characters >= minLength;
        const maxValid = characters <= maxLength;
        const remainingCharacters = maxLength - characters;
        this.setState({ value: data, minValid, maxValid, remainingCharacters, errors });

        // Mutate the data
        onMutation(data);
    };

    /**
     * Set the remaining characters when the editor is initialized
     * @param {*} editor
     */
    onInit = (editor) => {
        const { maxLength } = this.props;

        try {
            const data = editor.getData();
            const strippedString = data.replace(/(<([^>]+)>)/gi, '');
            const characters = strippedString.length;
            const remainingCharacters = maxLength - characters;
            this.setState({ remainingCharacters });
        } catch (error) {}
    };

    render() {
        const { className, disabled, minLength, maxLength, readOnly } = this.props;
        const { editorConfig, remainingCharacters, minValid, maxValid, value } = this.state;
        const editorConfigProp = this.props.editorConfig;

        if (readOnly) {
            return (
                <div className="input__rich-text-editor">
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.value ? this.props.value : '') }} />
                </div>
            );
        } else {
            return (
                <div className={'input__rich-text-editor ' + className}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <CKEditor
                            data={value}
                            readOnly={disabled}
                            config={{
                                toolbar: editorConfig.toolbar,
                                link: {
                                    addTargetToExternalLinks: editorConfig.addTargetToExternalLinks
                                },
                                ...editorConfigProp
                            }}
                            onReady={this.onInit}
                            onChange={this.onChangeHandler}
                        />
                    </Suspense>
                    <div className={'input__rich-text-editor__footer'}>
                        <div className="input__rich-text-editor__footer-container">
                            {minLength && (
                                <span
                                    className={`input__rich-text-editor__footer__word-count ${
                                        minValid ? '' : 'input__rich-text-editor__footer__word-count--invalid'
                                    }`}>
                                    Min. char.: {minLength}
                                </span>
                            )}
                            {maxLength && (
                                <span
                                    className={`input__rich-text-editor__footer__word-count ${
                                        maxValid ? '' : 'input__rich-text-footer-count__word-count--invalid'
                                    }`}>
                                    Max. char.: {maxLength}
                                </span>
                            )}

                            {maxLength && (
                                <span
                                    className={`input__rich-text-editor__footer__word-count ${
                                        maxValid ? '' : 'input__rich-text-footer-count__word-count--invalid'
                                    }`}>
                                    Char. left: {remainingCharacters}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default RichTextEditor;
