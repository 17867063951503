import React, { useEffect, useState } from 'react';
import Select from 'components/ui-components-v2/Select';
import User from 'components/data/User';
import EditorData from 'components/editor-data/EditorData';

interface Props {
    value: any;
    onMutation: (value: any) => void;
    readOnly?: boolean;
}

/**
 * SelectInput
 * Shows a selectbox the end user.
 * Outputs the value via onMutation.
 */
const OrganisationEntitySelect = (props: Props) => {
    const { value, onMutation } = props;
    const [localValue, setLocalValue] = useState<any>(value);

    // If there is no correct value set the first value
    useEffect(() => {
        const newOptions = getOptions();
        const hasNoValue = !value;
        const hasNoCorrectValue = value && !newOptions.find((option) => option.key === value);
        if ((hasNoValue || hasNoCorrectValue) && newOptions.length) {
            const firstOption = newOptions[0].key;
            onMutation(firstOption);
        }
    }, []);

    useEffect(() => {
        if (props.value !== localValue) {
            setLocalValue(props.value);
        }
    }, [props.value]);

    /**
     * Makes sure all options are formatted the same way
     * @returns Formatted options
     */
    const getOptions = (): { key: string; value: string }[] => {
        const organisationEntities = User.get('organisationEntities');
        const options: { key: string; value: string }[] = [];
        let channels = EditorData.getValueFromModel('settings.channels');
        if (!channels) {
            channels = [];
        }

        organisationEntities.forEach((organisationEntity) => {
            // If the organisation entity has segments, only show the ones that are in the settings
            if (organisationEntity && organisationEntity.segments) {
                if (organisationEntity.segments.some((item) => channels.includes(item))) {
                    options.push({ key: organisationEntity.id, value: organisationEntity.title });
                }
            } else {
                options.push({ key: organisationEntity.id, value: organisationEntity.title });
            }
        });
        return options;
    };

    /**
     * Value changed
     * Send to onMutation
     */
    const onValueChanged = (event) => {
        setLocalValue(event.target.value);
        onMutation(event.target.value);
    };

    const newOptions = getOptions();

    return (
        <Select native value={localValue} onChange={onValueChanged} variant="outlined" fullWidth margin="dense">
            {newOptions.map((displayOption) => (
                <option value={displayOption.key} key={displayOption.key}>
                    {displayOption.value}
                </option>
            ))}
        </Select>
    );
};

export default OrganisationEntitySelect;
