import React, { useEffect, useMemo } from 'react';
import CheckList from 'components/input/CheckList';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { LinkedInConversionMetaDataList, LinkedInConversionMetaData } from '../types/types';

interface Props extends MetadataInputProps {
    response?: LinkedInConversionMetaDataList;
    config: Config;
    value: LinkedInConversionMetaData[];
}

interface Config {
    account: string;
    q: string;
}

const LinkedInConversions = ({ response, config, value, setRequest, onMutation }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    // // Formatting the options based on the resposne
    const getOptions = (response: LinkedInConversionMetaDataList | undefined) => {
        if (!response || !response.data || !response.data.length) return undefined;

        return response.data.reduce((acc, item) => {
            acc[item.id] = `${item.name} (${item.id})`;
            return acc;
        }, {});
    };

    const options = useMemo(() => getOptions(response), [response]);

    if (!options || !Object.keys(options).length) return;

    return <CheckList options={options} onMutation={onMutation} value={value} />;
};

export default LinkedInConversions;
