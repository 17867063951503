import React from 'react';
import Translation from 'components/data/Translation';
import { FacebookDetailedTargetingMetaData } from '../types/FacebookDemographicTypes';

interface TooltipProps {
    item: FacebookDetailedTargetingMetaData;
    getPathAsText: (item: FacebookDetailedTargetingMetaData) => JSX.Element;
}

const InputTooltipPopper = ({ item, getPathAsText }: TooltipProps) => {
    /**
     * Separates the number with commas
     * @param number
     * @returns  number with commas
     */
    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className="input__facebook-detailed-targeting__input-tooltip-popper">
            {item.audience_size_lower_bound && item.audience_size_upper_bound && (
                <span className="input__facebook-detailed-targeting__input-tooltip-popper__audience">
                    {Translation.get('input.metadata.facebook.detailedTargeting.estimatedAudience', 'common')}:{' '}
                    {numberWithCommas(item.audience_size_lower_bound)}- {numberWithCommas(item.audience_size_upper_bound)}
                </span>
            )}
            {item.description && item.description !== null && (
                <span className="input__facebook-detailed-targeting__input-tooltip-popper__description">
                    {Translation.get('input.metadata.facebook.detailedTargeting.description', 'common')}: {item.description}
                </span>
            )}
            {item.delivery_status && item.delivery_status.description && (
                <span className="input__facebook-detailed-targeting__input-tooltip-popper__delivery-status">
                    {Translation.get('input.metadata.facebook.detailedTargeting.deliveryStatus', 'common')}: {item.delivery_status.description}
                </span>
            )}
            {item.subtype && (
                <span className="input__facebook-detailed-targeting__input-tooltip-popper__subtype">
                    {Translation.get('input.metadata.facebook.detailedTargeting.subtype', 'common')}: {item.subtype}
                </span>
            )}
            {item.path && (
                <span className="input__facebook-detailed-targeting__input-tooltip-popper__path">
                    {' '}
                    {Translation.get('input.metadata.facebook.detailedTargeting.path', 'common')}: {getPathAsText(item)}
                </span>
            )}
        </div>
    );
};

export default InputTooltipPopper;
