import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { Preset } from 'components/bricks/types/preset';
import './../styles/item.scss';

interface Props {
    multiple?: boolean;
    preset: Preset;
    setPresetsDialogOpen: Dispatch<SetStateAction<boolean>>;
    removePreset: (preset: Preset) => void;
}

const PRESET_OVERVIEW_MAX_PX = 24;

const PresetSelectorItem = ({ preset, multiple, setPresetsDialogOpen, removePreset }: Props) => {
    const getStyleContainer = useCallback((preset: Preset) => {
        /**
         * Calculate the thumbnail size based on the width and height.
         */
        const previewSize: CSSProperties = {
            width: PRESET_OVERVIEW_MAX_PX,
            height: PRESET_OVERVIEW_MAX_PX
        };

        const width = preset.restrictions?.width || preset.restrictions?.minWidth;
        const height = preset.restrictions?.height || preset.restrictions?.minHeight;
        if (!width || !height) return previewSize;
        if (width > height) {
            previewSize.height = (height / width) * PRESET_OVERVIEW_MAX_PX;
        } else {
            previewSize.width = (width / height) * PRESET_OVERVIEW_MAX_PX;
        }

        return previewSize;
    }, []);

    const descriptionRef = useRef<HTMLParagraphElement>(null);

    const isDescriptionTruncated = useMemo(() => {
        const element = descriptionRef.current;

        return element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false;
    }, [descriptionRef.current]);

    return (
        <div
            className={classNames('input-preset-selector-item', {
                'input-preset-selector-item--single-preset': !multiple
            })}
            key={preset.identifier}>
            <div className="input-preset-selector-item__overview">
                <span className="input-preset-selector-item__overview__placement" style={getStyleContainer(preset)} />
            </div>
            <div className="input-preset-selector-item__metadata">
                <div className="input-preset-selector-item__title">{preset.title}</div>
                <div className="input-preset-selector-item__subtitle">
                    <Tooltip title={preset.description} disableHoverListener={!isDescriptionTruncated} placement="top-start">
                        <span className="input-preset-selector-item__subtitle__text" ref={descriptionRef}>
                            {preset.description} | {preset.channel}
                        </span>
                    </Tooltip>
                </div>
            </div>

            {!multiple && (
                <IconButton onClick={() => setPresetsDialogOpen(true)}>
                    <Icon>edit_outline</Icon>
                </IconButton>
            )}

            <IconButton onClick={() => removePreset(preset)} className="input-preset-selector-item__delete-button">
                <Icon>delete_outline</Icon>
            </IconButton>
        </div>
    );
};

export default PresetSelectorItem;
