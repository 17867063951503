import React, { useEffect, useMemo, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataSearch } from 'components/input/Metadata/shared';
import { FacebookDetailedTargetingMetaDataList, FacebookDetailedTargetingMetaData } from '../types/FacebookDemographicTypes';
import InputTooltipPopper from './input-tooltip-popper';
import '../styles/main.scss';

interface Props extends MetadataInputProps {
    response?: FacebookDetailedTargetingMetaDataList;
    config: Config;
    value: FacebookDetailedTargetingMetaData[] | number | string;
}

interface Config {
    accountId?: string;
    type?: string;
    searchClass?: string;
    q?: string;
}

/**
 * Facebook interest/behavior metadata input component
 *
 */
const FacebookDetailedTargeting = ({ response, config, value, setRequest, onMutation }: Props) => {
    const [q, setQ] = useState<string | undefined>(undefined);
    const [customConfig, setCustomConfig] = useState<Config>(config);

    useEffect(() => {
        if (typeof value === 'string') onMutation(value.split(','));
        else if (typeof value === 'number') onMutation([value.toString()]);
    }, []);

    useEffect(() => {
        // set parameters to start a request
        if ((config.type === 'adinterest' && q && q.length) || (config.type !== 'adinterest' && !response)) {
            setRequest({
                params: {
                    ...customConfig
                }
            });
        }
    }, [JSON.stringify(customConfig)]);

    // Formatting the options based on the resposne
    const getOptions = (response: FacebookDetailedTargetingMetaDataList | undefined) => {
        if (!response || !response.data || !response.data.length) return undefined;
        // Format the options for the interests
        else if (config.type === 'adinterest') {
            return response.data.filter((interest) => {
                if (!value) return true;

                // Check if the interest is not selected already
                return Array.isArray(value) && value.every((selectedInterest: FacebookDetailedTargetingMetaData) => selectedInterest.id !== interest.id);
            });
        } else if (config.type === 'adlocale') {
            return response.data.reduce((acc: FacebookDetailedTargetingMetaData[], language: FacebookDetailedTargetingMetaData) => {
                if (!q || q === '') return acc;

                // Check if the item is not selected already
                const isItemSelected = Array.isArray(value)
                    ? value.every((selectedItem: FacebookDetailedTargetingMetaData) => selectedItem.id !== language.id)
                    : true;

                // Retrieve the options which include q
                if (isItemSelected && q && language.name.toLowerCase().includes(q.toLowerCase()) && language.key) {
                    acc.push({ id: language.key, name: language.name, type: 'language' });
                }
                return acc;
            }, []);
        } else {
            return response.data.filter((item) => {
                if (!q || q === '') return false;

                // Check if the item is not selected already
                const isItemSelected = Array.isArray(value)
                    ? value.every((selectedItem: FacebookDetailedTargetingMetaData) => selectedItem.id !== item.id)
                    : true;

                // Retrieve the options which include q
                return isItemSelected && q && item.name.toLowerCase().includes(q.toLowerCase());
            });
        }
    };

    const options = useMemo(() => getOptions(response), [response, q]);

    // Get path of the item and highthlight the item itself
    const getPathAsText = (item: FacebookDetailedTargetingMetaData): ReactJSXElement => {
        if (!item.path) return <span>{item.name}</span>;
        const pathCopy = [...item.path];

        // Delete the first item of the path if it is 'Interests' or 'Behaviors'
        if (['Interests', 'Behaviors'].includes(pathCopy[0])) pathCopy.splice(0, 1);

        return (
            <div>
                {pathCopy.map((item, i) => (
                    <span key={i} style={i === pathCopy.length - 1 ? { fontWeight: 'bold' } : {}}>{`${item} ${i !== pathCopy.length - 1 ? ' > ' : ''}`}</span>
                ))}
            </div>
        );
    };

    const getTooltipPopper = (item: FacebookDetailedTargetingMetaData) => {
        return <InputTooltipPopper item={item} getPathAsText={getPathAsText} />;
    };

    return (
        <MetadataSearch
            isQueryParamRequired
            options={options}
            value={Array.isArray(value) ? value : []}
            onMutation={onMutation}
            setCustomConfig={setCustomConfig}
            customConfig={customConfig}
            q={q}
            setQ={setQ}
            showName={getPathAsText}
            tooltipPopper={config.type !== 'adlocale' ? getTooltipPopper : undefined}
        />
    );
};

export default FacebookDetailedTargeting;
