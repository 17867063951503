import { FormControlLabel, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Radio from 'components/ui-components-v2/Radio';

/**
 * Radio list
 * Displays a list of radio buttons
 */
export default class RadioList extends Component {
    static propTypes = {
        value: PropTypes.any,
        /** Options to add to the radiolist */
        options: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
            PropTypes.objectOf(PropTypes.string)
        ]),
        endAdornment: PropTypes.string,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        sortOptions: PropTypes.bool
    };

    static defaultProps = {
        value: '',
        options: [],
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    /**
     * handleChange
     * Change the radiolist value
     */
    handleChange = (event) => {
        const { onMutation } = this.props;
        this.setState({ value: event.target.value });
        onMutation(event.target.value);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value && this.state.value == prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        const { value } = this.state;
        const { sortOptions, endAdornment } = this.props;

        // Create options array
        let formattedOptions = this.props.options;
        if (!(formattedOptions instanceof Array)) {
            formattedOptions = Object.keys(formattedOptions).map((x) => ({ key: x, value: formattedOptions[x] }));
        }
        if (sortOptions) {
            formattedOptions.sort((a, b) => {
                const aKeyAsNumber = parseInt(a.key, 10);
                const bKeyAsNumber = parseInt(b.key, 10);
                const aIsNumber = !isNaN(aKeyAsNumber);
                const bIsNumber = !isNaN(bKeyAsNumber);

                if (aIsNumber && !bIsNumber) return -1;
                if (!aIsNumber && bIsNumber) return 1;
                // Convert keys to numbers and compare
                return aKeyAsNumber - bKeyAsNumber;
            });
        }
        return (
            <RadioGroup value={value} onChange={this.handleChange}>
                {formattedOptions.map((x, index) => (
                    <FormControlLabel value={x.value} control={<Radio name={x.key} value={x.key} />} label={`${x.value}${endAdornment ?? ''}`} key={index} />
                ))}
            </RadioGroup>
        );
    }
}
