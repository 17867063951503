import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid/Grid';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Dialog from 'components/ui-components-v2/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Input from '@mui/material/Input';
import debounce from 'lodash/debounce';
import Slider from 'components/ui-components-v2/Slider';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { FacebookGeographicMetaData, DialogObject } from '../types/FacebookGeographicTypes';

interface Props {
    value: FacebookGeographicMetaData[] | string[];
    dialog: DialogObject;
    onMutation: (value: unknown, type?: string) => void;
    setDialog: (dialog: DialogObject) => void;
    minRadius: number;
    maxRadius: number;
}

const RadiusDialog = ({ value, onMutation, dialog, setDialog, minRadius, maxRadius }: Props) => {
    const updateValue = debounce((newValue) => onMutation(newValue), 400);
    const updateDataCb = useCallback((newValue) => updateValue(newValue), []);

    const handleRadius = (radius, index) => {
        if (typeof value[index] === 'string') return;
        const newValue = value;

        const oldDialog = { ...dialog };

        (newValue[index] as FacebookGeographicMetaData).radius = parseInt(radius);
        setDialog({ ...oldDialog, dialogRadius: (value[index] as FacebookGeographicMetaData).radius as number });

        updateDataCb(newValue);
    };

    const dialogClose = () => {
        const oldDialog = dialog;
        setDialog({ ...oldDialog, dialogOpen: false });
    };

    const dialogCloseEmpty = () => {
        const oldDialog = dialog;
        const dialogIndex = dialog.dialogIndex;
        if (typeof value[dialogIndex] === 'string') return;
        (value[dialogIndex] as FacebookGeographicMetaData).radius = undefined;

        setDialog({ ...oldDialog, dialogOpen: false });
    };

    return (
        <div>
            {dialog && dialog.dialogOpen && (
                <Dialog open={true} maxWidth="sm" onClose={dialogClose} fullWidth={true}>
                    <DialogTitle id="customized-dialog-title"> {Translation.get('input.metadata.facebook.geographic.selectRadius', 'common')}</DialogTitle>
                    <DialogContent dividers>
                        <div className="input__facebook-geographic__dialog__distance">
                            <Button variant="outlined" onClick={dialogCloseEmpty} style={{ width: '100%', marginBottom: 20 }}>
                                {Translation.get('input.metadata.facebook.geographic.useCurrentLocation', 'common')}
                            </Button>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Slider
                                        valueLabelDisplay="auto"
                                        min={minRadius}
                                        max={maxRadius}
                                        step={1}
                                        marks={false}
                                        value={dialog.dialogRadius}
                                        onChange={(event, value) => handleRadius(value, dialog.dialogIndex)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Input
                                        value={dialog.dialogRadius}
                                        margin="dense"
                                        onChange={(event) => handleRadius(event.target.value, dialog.dialogIndex)}
                                        inputProps={{
                                            min: 0,
                                            max: 80,
                                            type: 'number'
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <div>{Translation.get('input.metadata.facebook.geographic.km', 'common')}</div>
                                </Grid>
                            </Grid>
                            <Button variant="outlined" onClick={dialogClose} style={{ width: '100%', marginTop: 20 }}>
                                {Translation.get('input.metadata.facebook.geographic.closeLocation', 'common')}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default RadiusDialog;
