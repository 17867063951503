import React, { useEffect } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataAutocomplete } from 'components/input/Metadata/shared';

interface Props extends MetadataInputProps {
    response?: any;
    config: {
        profileId: string;
        key: string;
    };
    value: string;
    readOnly?: boolean;
    metadataType: string;
}

const CM360Generic = ({ response, value, config, readOnly, setRequest, onMutation }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    if (!response || !response.data) return;

    return <MetadataAutocomplete readOnly={readOnly} options={response.data} value={value} onMutation={onMutation} />;
};

export default CM360Generic;
