import React, { useEffect, useState } from 'react';
import { MultiLevelCheckboxList } from 'components/input/MultiLevelCheckboxList';
import Setup from 'components/data/Setup';

interface Props {
    value: any;
    onMutation: (value: any) => void;
    readOnly?: boolean;
}

/**
 * SelectInput
 * Shows a selectbox the end user.
 * Outputs the value via onMutation.
 */
const OrganisationEntityChecklist = (props: Props) => {
    const { value = [], onMutation } = props;
    const [localValue, setLocalValue] = useState<any>(value);

    useEffect(() => {
        if (props.value !== localValue) {
            setLocalValue(props.value);
        }
    }, [props.value]);

    /**
     * Value changed
     * Send to onMutation
     */
    const onChange = (value) => {
        onMutation(value.checkedItems);
    };

    /**
     * Get options for list
     * @returns
     */
    const getOptions = () => {
        const setSection = (array) => {
            array.forEach((item) => {
                item.type = 'selectable-section';
                if (item.children) {
                    setSection(item.children);
                }
            });
        };

        const map = Setup.getValueFromModel('organisationEntities.map');
        setSection(map);
        return map;
    };

    return (
        <div>
            <MultiLevelCheckboxList items={getOptions() as Array<any>} value={{ checkedItems: value as any }} onMutation={onChange} identifier="key" />
        </div>
    );
};

export default OrganisationEntityChecklist;
