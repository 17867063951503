export const PropertyMapping = {
    tiktokLocations: {
        name: 'name',
        id: 'location_id',
        description: 'level',
        aditional_info: 'country_code'
    },
    tiktokLanguages: {
        name: 'name',
        id: 'code',
        description: 'code'
    },
    tiktokInterestsAndBehaviors: {
        name: 'keyword',
        id: 'keyword_id',
        description: 'language'
    },
    tiktokDeviceModels: {
        name: 'device_model_name',
        id: 'device_model_id',
        description: 'os_type'
    },
    tiktokCarriers: {
        name: 'name',
        id: 'carrier_id',
        description: 'os_type'
    }
};
