import React, { useEffect, useMemo, useState } from 'react';
import '../styles/main.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from 'components/ui-components/TextField';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import '../styles/main.scss';
import { LinkedInMetaData, LinkedInMetaDataList } from '../types/types';
import ValueBlock from './value-block';

interface Props extends MetadataInputProps {
    response?: LinkedInMetaDataList;
    config: Config;
    value: LinkedInMetaData[];
}

interface Config {
    q?: string;
    queryVersion?: string;
    facet?: string;
}

const LinkedInSelectMultiple = ({ response, config, value, setRequest, onMutation }: Props) => {
    const [q, setQ] = useState<string | undefined>('');

    useEffect(() => {
        if (!q || q === '') return;
        // set parameters to start a request
        setRequest({
            params: {
                ...config,
                query: q
            }
        });
    }, [JSON.stringify(config), q]);

    // Formatting the options based on the resposne
    const getOptions = (response: LinkedInMetaDataList | undefined) => {
        if (!response || !response.data || !response.data.length) return undefined;

        return response.data.filter((item) => {
            // Check if the item is not selected already
            const isItemSelected = value ? value.some((selectedItem: LinkedInMetaData) => selectedItem.urn === item.urn) : false;

            // Retrieve the options which include q
            return !isItemSelected && q && item.name.toLowerCase().includes(q.toLowerCase());
        });
    };

    const options = useMemo(() => getOptions(response), [response, q]);

    // Highlights the text
    const getHighlightedText = (title, highlight = '') => {
        // Split on highlight term and include term into parts, ignore case

        const parts = title.split(new RegExp(`(${highlight})`, 'gi'));

        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    const handleSetQ = (event) => {
        setQ(event.target.value);
    };

    /**
     * Add an item
     * @param {*} item
     */
    const handleItemClick = (item) => {
        let newValue: Array<object> = [];
        if (value) newValue = value;

        newValue.push(item);
        onMutation(newValue);
        setQ('');
    };

    // Close the available items window
    const handleClickAway = () => {
        if (q && q.length) setQ('');
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="input__linkedin-select-multiple">
                <div className="input__linkedin-select-multiple__input">
                    <TextField fullWidth onChange={handleSetQ} value={q} margin="none" variant="outlined" />
                </div>
                {options && options.length > 0 && q && q !== '' && (
                    <div className="input__linkedin-select-multiple__results">
                        {options.map((item: LinkedInMetaData, i) => (
                            <div className="input__linkedin-select-multiple__results__item" onClick={() => handleItemClick(item)} key={`result-${i}`}>
                                <div className="input__linkedin-select-multiple__results__item__name">{getHighlightedText(item.name, q)}</div>
                                <div className="input__linkedin-select-multiple__results__item__type">{item.urn}</div>
                            </div>
                        ))}
                    </div>
                )}
                {value && !!value.length && <ValueBlock value={value} onMutation={onMutation} />}
            </div>
        </ClickAwayListener>
    );
};

export default LinkedInSelectMultiple;
