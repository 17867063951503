import React, { useEffect } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { GoogleAdsMetaDataList } from '../types/GoogleAdsTypes';
import { SelectInput } from '../../../../../SelectInput';

interface Props extends MetadataInputProps {
    response?: GoogleAdsMetaDataList;
    config: {
        adAccountId?: string;
        fields?: string;
    };
}

/**
 * LinkedIn metadata input component
 *
 */
export const GoogleAdsCampaigns = ({ response, value, config, setRequest, onMutation }: Props) => {
    useEffect(() => {
        setRequest({
            params: { ...config }
        });
    }, []);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (selectedValue: string) => {
        onMutation(selectedValue);
    };

    if (!response) return;

    //to do useMemo();
    const newObject = {};
    if (response) {
        if (response) {
            response.data.forEach((item) => {
                newObject[item.entityTypes[0]] = item.facetName;
            });
        }
        return <SelectInput options={newObject} value={value} onMutation={handleChange} />;
    }
};
