import React, { useState, useRef } from 'react';
import cloneDeep from 'helpers/cloneDeep';
import MultiLevelCheckboxListItem from './item';
import MultiLevelCheckboxSection from './section';

import '../styles/main.scss';

export type Identifier = 'key' | 'id';

export interface ChecklistOptionId extends ChecklistOption {
    id: string;
}
export interface ChecklistOptionKey extends ChecklistOption {
    key: string;
}

export interface Value {
    checkedItems: string[];
    previewItem?: string;
}

interface Props {
    items: ChecklistOptionId[] | ChecklistOptionKey[];
    onMutation: (value: Value) => void;
    onMouseHoverItem?: (key?: string) => void;
    selectOnlyChildren?: boolean;
    value?: Value;
    identifier: Identifier;
}

export interface ChecklistOption {
    type: 'section' | 'item' | 'selectable-section';
    label: string;
    icon?: string | JSX.Element;
    disabled?: boolean;
    hasErrors?: boolean;
    canPreview?: boolean;
    children?: any;
}

/**
 * This component renders a nested checklist, based on the items it receives. It also checks for indeterminate states.
 * Be aware that this component only returns the items that are deepest in the tree (the ones without any children themselves).
 */
const MultiLevelCheckboxList: React.FunctionComponent<Props> = ({
    items = [],
    onMutation,
    onMouseHoverItem,
    value = { checkedItems: [] },
    identifier = 'key',
    selectOnlyChildren = false
}) => {
    const selectedRef = useRef<Value>(value);
    const [, setStateCount] = useState<number>(0); // make sure the component rerenders

    const handleChange = (checked: boolean, item: ChecklistOption, setPreview = false) => {
        if (setPreview) {
            handleSetPreview(item);
            return handleCheck(item);
        }
        if (checked) {
            handleCheck(item);
        } else {
            handleUncheck(item);
        }
    };

    /**
     * Sets the item for preview
     * @param item The checklist option that is for preview
     */
    const handleSetPreview = (item: ChecklistOption) => {
        selectedRef.current.previewItem = item[identifier];
    };

    /**
     * When an item gets checked, we also check all of its children. However, only the items
     * that have no children get added the the list of items that are saved.
     * @param item The checkllist option that is selected
     */
    const handleCheck = (item: ChecklistOption) => {
        if (!selectedRef.current || !selectedRef.current.checkedItems) selectedRef.current.checkedItems = [];

        if (!item.children && !selectedRef.current.checkedItems.includes(item[identifier])) {
            selectedRef.current.checkedItems.push(item[identifier]);
        }
        if (!selectOnlyChildren && !selectedRef.current.checkedItems.includes(item[identifier]) && !item.disabled) {
            selectedRef.current.checkedItems.push(item[identifier]);
        }

        if (item.children && item.children.length > 0 && !item.disabled) {
            item.children.forEach((child: ChecklistOption) => {
                handleCheck(child);
            });
        } else {
            setStateCount(Math.random());
            onMutation(cloneDeep(selectedRef.current));
        }
    };

    // Uncheck items and its optional children
    const handleUncheck = (item: ChecklistOption) => {
        selectedRef.current.checkedItems = selectedRef.current.checkedItems.filter((key: string) => key !== item[identifier]);

        if (item.children && item.children.length > 0) {
            item.children.forEach((child: ChecklistOption) => {
                selectedRef.current.checkedItems = selectedRef.current.checkedItems.filter((key: string) => key !== child[identifier]);
            });

            item.children.forEach((child: ChecklistOption) => {
                handleUncheck(child);
            });
        } else {
            setStateCount(Math.random());
            onMutation(cloneDeep(selectedRef.current));
        }
    };

    return (
        <div className="multi-level-checkbox-list">
            {items.map((item: ChecklistOption) => {
                if (item.type === 'item')
                    return (
                        <MultiLevelCheckboxListItem
                            key={item[identifier]}
                            identifier={identifier}
                            checkedItems={value.checkedItems}
                            item={item}
                            value={selectedRef.current}
                            onChange={handleChange}
                        />
                    );
                else if (item.type === 'section')
                    return (
                        <MultiLevelCheckboxSection
                            key={item[identifier]}
                            item={item}
                            value={selectedRef.current}
                            onChange={handleChange}
                            onMouseHoverItem={onMouseHoverItem}
                        />
                    );
                else if (item.type === 'selectable-section')
                    return (
                        <MultiLevelCheckboxListItem
                            key={item[identifier]}
                            identifier={identifier}
                            checkedItems={value.checkedItems}
                            item={item}
                            type="expandable"
                            value={selectedRef.current}
                            onChange={handleChange}
                            onMouseHoverItem={onMouseHoverItem}
                        />
                    );
            })}
        </div>
    );
};

export default MultiLevelCheckboxList;
