import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'components/ui-components-v2/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from 'components/ui-components-v2/Select';
import EditorData from 'components/editor-data/EditorData';
import MediaSetSelectorThumb from './media-set-selector-thumb';
import '../styles/main.scss';

class MediaSetSelector extends React.Component {
    static propTypes = {
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : {},
            mediaSets: this.getMediaSets(props.dataModel, props.assetType)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value != this.props.value && this.state.value == prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    getMediaSets = (dataModel, assetType) => {
        const mediaSets = EditorData.getValueFromModel(dataModel);
        if (assetType) {
            const filterdMediaSets = {};
            Object.entries(mediaSets).forEach((entry) => {
                const [key, value] = entry;
                if (value.type === assetType) {
                    filterdMediaSets[key] = value;
                }
            });
            return filterdMediaSets;
        }
        return mediaSets;
    };

    handleChange = (e) => {
        const { mediaSets } = this.state;
        const uuid = e.target.value;
        const value = {
            ...mediaSets[uuid],
            uuid
        };
        this.setState({
            value
        });

        this.props.onMutation(value);
    };

    render() {
        const { templates } = this.props;
        const { value, mediaSets } = this.state;

        return (
            <Select
                margin="dense"
                fullWidth
                variant="outlined"
                className="media-set-selector"
                defaultValue=""
                onChange={this.handleChange}
                classes={{
                    selectMenu: 'media-set-selector__select-menu'
                }}
                value={value && value.uuid ? value.uuid : ''}>
                {value === '' && (
                    <MenuItem value="">
                        <MediaSetSelectorThumb value={null} templates={templates} />
                        <ListItemText primary="Select a media set" secondary="..." />
                    </MenuItem>
                )}
                {mediaSets &&
                    Object.entries(mediaSets).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            <MediaSetSelectorThumb value={value} templates={templates} />
                            <ListItemText primary={value.title} secondary={value.type} />
                        </MenuItem>
                    ))}
            </Select>
        );
    }
}

export default MediaSetSelector;
